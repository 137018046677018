$primary-color :#f09C01;
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap');

@mixin sidebarTab {
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: center;
    padding: 6px;
    cursor: pointer;
    margin: 6px 0;
}

@mixin sidebarBtnText {
    line-height: 30px;
    font-size: 17px;
    margin-right: 5px;
}

.home-wrapper {
    display: flex;
    width: 100%;
    height: 100%;
}

.sidebarBtn {
    @include sidebarTab();
    background-color: transparent;

    &:hover {
        background-color: rgba(0, 0, 0, 0.03);
    }
}

.sidebarNestedBtn {
    @include sidebarTab();
    background-color: transparent;

    &:hover {
        background-color: rgba(0, 0, 0, 0.03);
    }

}

.sidebarNestedBtnSelected {
    @include sidebarTab();
    background-color: $primary-color ;
    box-shadow: 0 12px 20px -10px rgba(232, 41, 83, 0.28), 0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(0, 145, 224, 0.2);
    margin: 6px 3px;
    border-radius: 3px;
}

.sidebarBtnSelected {
    @include sidebarTab();
    background-color: $primary-color ;
    box-shadow: 0 12px 20px -10px rgba(232, 41, 83, 0.28), 0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(0, 145, 224, 0.2);
    margin: 6px 3px;
    border-radius: 3px;
}


.sidebarBtnIcon {
    margin-right: 15px;
    margin-left: 6px;
}

.sidebarBtnTextSelected {
    @include sidebarBtnText();
    color: #ffffff;
}

.sidebarBtnText {
    @include sidebarBtnText();
    color: #3c4858;
}

.sidebarBackground {
    background-image: url('../../assets/images/sidebarBg.jpg');
    position: absolute;
    z-index: 1;
    height: 100%;
    width: 100%;
    display: block;
    top: 0;
    left: 0;
    background-size: cover;
    background-position: center center;
}

.sidebarBackground:after {
    position: absolute;
    z-index: 3;
    width: 100%;
    height: 100%;
    content: "";
    display: block;
    background: #FFFFFF;
    opacity: .93;
}


.MuiListItem-gutters {
    padding-left: 0 !important;
    padding-right: 0 !important;
}

.MuiListItem-root {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}

/* box shadow for sidebar */
.MuiDrawer-paperAnchorDockedLeft {
    border-right: 1px solid rgba(0, 0, 0, 0.12);
    box-shadow: 0 10px 30px -12px rgba(0, 0, 0, 0.42), 0 4px 25px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2) !important;
}

// ------------
.homeContainer {
    margin: 10px;
}

.navbarText {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}

//Home page 


// table 
// .ant-table-wrapper{
//     margin-top:3%;
// }

.ant-table table {
    table-layout: auto !important;
}

.ant-table.ant-table-bordered>.ant-table-container>.ant-table-content>table>thead>tr>th,
.ant-table.ant-table-bordered>.ant-table-container>.ant-table-header>table>thead>tr>th,
.ant-table.ant-table-bordered>.ant-table-container>.ant-table-body>table>thead>tr>th,
.ant-table.ant-table-bordered>.ant-table-container>.ant-table-content>table>tbody>tr>td,
.ant-table.ant-table-bordered>.ant-table-container>.ant-table-header>table>tbody>tr>td,
.ant-table.ant-table-bordered>.ant-table-container>.ant-table-body>table>tbody>tr>td,
.ant-table.ant-table-bordered>.ant-table-container>.ant-table-content>table>tfoot>tr>th,
.ant-table.ant-table-bordered>.ant-table-container>.ant-table-header>table>tfoot>tr>th,
.ant-table.ant-table-bordered>.ant-table-container>.ant-table-body>table>tfoot>tr>th,
.ant-table.ant-table-bordered>.ant-table-container>.ant-table-content>table>tfoot>tr>td,
.ant-table.ant-table-bordered>.ant-table-container>.ant-table-header>table>tfoot>tr>td,
.ant-table.ant-table-bordered>.ant-table-container>.ant-table-body>table>tfoot>tr>td {
    white-space: nowrap !important;
}


// ========> dropdown input styles
#inputField {
    border: 1px #d2d2d2 solid;
    border-radius: 4px;
}

.MuiButton-label:focus {
    outline: none !important;
}

/* input styles */
.MuiInputBase-input {
    padding: 10px 12px !important;
}

/* bordder bottom */
.MuiInput-underline:before {
    border-bottom: 0px !important;
}

/* border bottom on hover */
.MuiInput-underline:hover:not(.Mui-disabled):before {
    border-bottom: 0px solid #f09C01 !important;
}

/* margin bottom to each input */

/* border animation under input */
.MuiInput-underline:after {
    left: 1px !important;
    right: 1px !important;
    content: "";
    position: absolute;
    transform: scaleX(0);
    transition: transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
    border-bottom: 2px solid #f09C01 !important;
    border-bottom-left-radius: 100px;
    border-bottom-right-radius: 100px;
    pointer-events: none;
}

// border profile pic
.ant-upload.ant-upload-select-picture-card {
    border: none !important;
}

.ant-upload.ant-upload-select-picture-card {
    margin: 0 !important;
}

.navbar-brand {
    margin-right: 0 !important;
}

// ******

.ant-table-thead>tr>th {
    font-size: 19px !important;
    color: #000000DE !important;
}

.ant-table-tbody>tr>td {
    font-size: 17px !important;
    color: #000000DE !important;

}

.ant-pagination-item-active {

    border-color: #f09C01 !important;
}

.ant-pagination-item:hover {

    border: 1px solid #d9d9d9;
}

.ant-pagination-item-link {
    border: 1px solid #d9d9d9 !important;
    color: #000000 !important;

}

.ant-table-thead {
    user-select: none;
}

.dashboard-card-icon {

    height: 40px !important;
    width: 40px !important;
    color: #ffffff;
}

.dashboard-card-body {

    color: #f09C01;
    position: relative;
    height: 140px;
    display: flex;
    flex-direction: column;
}

.MuiFormHelperText-root.Mui-error {
    color: #f09C01 !important;
}

.card-body-floating-box1 {

    height: 90px;
    width: 90px;
    background: linear-gradient(60deg, #ffa726, #fb8c00);
    box-shadow: 0 12px 20px -10px rgb(255 152 0 / 28%), 0 4px 20px 0px rgb(0 0 0 / 12%), 0 7px 8px -5px rgb(255 152 0 / 20%);
    position: absolute;
    top: -50px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.card-body-floating-box2 {

    height: 90px;
    width: 90px;
    background: linear-gradient(60deg, #66bb6a, #43a047);
    box-shadow: 0 12px 20px -10px rgb(76 175 80 / 28%), 0 4px 20px 0px rgb(0 0 0 / 12%), 0 7px 8px -5px rgb(76 175 80 / 20%);
    position: absolute;
    top: -50px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.card-body-floating-box3 {

    height: 90px;
    width: 90px;
    background: linear-gradient(60deg, #ef5350, #e53935);
    box-shadow: 0 12px 20px -10px rgb(244 67 54 / 28%), 0 4px 20px 0px rgb(0 0 0 / 12%), 0 7px 8px -5px rgb(244 67 54 / 20%);
    position: absolute;
    top: -50px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.card-body-floating-box4 {

    height: 90px;
    width: 90px;
    background: linear-gradient(60deg, #26c6da, #00acc1);
    box-shadow: 0 12px 20px -10px rgb(0 188 212 / 28%), 0 4px 20px 0px rgb(0 0 0 / 12%), 0 7px 8px -5px rgb(0 188 212 / 20%);
    position: absolute;
    top: -50px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.dashboard-card-body-value {

    position: absolute;
    width: 85%;
    text-align: end;
    font-size: 50px;
    top: -10px;
    color: #3c4858;
    opacity: 0.8;
}

.table-action-button {
    width: 80px !important;
    background: #f09C01;
    border: none;
    outline: none;
    color: #fff;
    margin-right: 10px;
    height: 38px;
    border-radius: 3px;
    font-size: 14px;
    font-weight: 500;
}

.table-action-button3 {
    width: 80px !important;
    background: red;
    border: none;
    outline: none;
    color: #fff;
    margin-right: 10px;
    height: 38px;
    border-radius: 3px;
    font-size: 14px;
    font-weight: 500;
}

.table-action-button2 {
    width: 170px !important;
    background: #f09C01;
    border: none;
    outline: none;
    color: #fff;
    margin-right: 10px;
    height: 38px;
    border-radius: 3px;
    font-size: 14px;
    font-weight: 500;
}

.ant-table-column-sorters {

    width: 100%;
    display: flex;
    justify-content: space-between;
}

.add-category-button {

    height: 45px;
    background: linear-gradient(60deg, #f09C01, #f09C01);
    box-shadow: 0 12px 20px -10px rgb(156 39 176 / 28%), 0 4px 20px 0px rgb(0 0 0 / 12%), 0 7px 8px -5px rgb(156 39 176 / 20%);
    color: #ffffff;
    font-size: 18px;
    border: none;
    outline: none;
    padding: 5px 20px;
    margin-bottom: 20px;
    border-radius: 3px;
    cursor: pointer;
}

.delete-category-modal .delete-category-modal-text {

    font-size: 15px;
    font-weight: 400;
}

.delete-category-modal button {

    background: #f09C01;
    color: #ffffff;
    height: 35px;
    width: 80px;
    border: none;
    outline: none;
    border-radius: 3px;
    font-size: 15px;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;

}

.delete-category-modal-footer {

    width: 100%;
    display: flex;
    justify-content: flex-end;

}

.MuiAppBar-colorTransparent {
    z-index: 1 !important;
}

.ant-table-pagination {

    display: none;
}

.table-pagination {

    margin: 20px 0;
    display: flex;
    justify-content: flex-end;
}

.ant-pagination-item-active {

    border-color: #f09C01 !important;
}

button{
    margin: 15px;

}